import React from "react"
import { Helmet } from "react-helmet"

import Header from "../Header"
import Footer from "../Footer"

import { NavigationProvider } from "../navigation-context"

// Global styles and component-specific styles.
import "./global.css"
import * as styles from "./main.module.css"
import './bootstrap.min.css'

const Layout = ({ children }) => (

  <NavigationProvider>
    <Helmet title="MediaLinq" />
    <div className="site">
      <Header />
      <div className="site-content">
        <main className={styles.main}>{children}</main>
      </div>
      <Footer></Footer>
    </div>
  </NavigationProvider>
)

export default Layout
