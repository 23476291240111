import React from "react"
import * as styles from "./header.module.css"
import styled from "styled-components"

const HeaderLink = styled.a`
    color: white;
    text-decoration: none;
    
     &:hover {
      color: white;
      text-decoration: none;
    }
`

const Header = () => (
  <header className={styles.header}>
    <div className={styles[`header__wrap`]}>
      <h1 className={[styles[`header__heading`], "pl-3"].join(" ")}>
        <HeaderLink href={"https://www.medialinq.se"}>MediaLinq</HeaderLink>
      </h1>
    </div>
  </header>
)

export default Header
