import React from "react"
import Layout from "../components/Layout"
import View from "../components/View"

const NoInternet = () => (
  <Layout>
    <View title="Internet connectivity problem">
      <p>Kontrollera din internetförbindelse och försök igen.</p>
    </View>
  </Layout>
)

export default NoInternet
