import React from "react"
import PropTypes from "prop-types"
import * as styles from "./view.module.css"

const View = ({title, wide, children}) =>  {

  const view_class = (wide ? styles.wideview : styles.view);


  return (
    <section className={view_class}>
      <h1 className={styles[`view__heading`]}>{title}</h1>
      {children}
    </section>
  )
}


View.propTypes = {
  title: PropTypes.string,
  wide: PropTypes.bool,
}

View.defaultProps = {
  title: "",
  wide: false,
}

export default View
